import axios from '@/libs/api.request'

export const getActivityAPI = () => {
    return axios.request({
        url: 'activity',
        method: 'get'
    })
}

export const getCountActivityAPI = () => {
    return axios.request({
        url: 'activity-count',
        method: 'get'
    })
}

export const getActivityByIdAPI = (id) => {
    return axios.request({
        url: 'activity/' + id,
        method: 'get'
    })
}

export const createActivityAPI = (credentials) => {
    return axios.request({
        url: 'activity',
        data: credentials,
        method: 'post'
    })
}

export const updateActivityAPI = (credentials) => {
    return axios.request({
        url: 'activity/' + credentials.id,
        data: credentials,
        method: 'put'
    })
}

export const deleteActivityAPI = (id) => {
    return axios.request({
        url: 'activity/' + id,
        method: 'delete'
    })
}